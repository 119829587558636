/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import actionTypes from '../constants/actionTypes';
import { CALL_API, helperGetCSRFCookieToken } from '../middleware/api';
import dayjs from '../config/DayjsConfig';

export function phoneVerifyRequest() {
  return {
    type: actionTypes.twoFactorPhoneVerifyRequest,
  };
}

export function phoneVerifyError(error) {
  return {
    type: actionTypes.twoFactorPhoneVerifyFailure,
    error
  };
}

export function phoneVerifySuccess(payload) {
  return {
    type: actionTypes.twoFactorPhoneVerifySuccess,
    payload
  };
}

export function incrementTokenVerificationAttempts() {
  return {
    type: actionTypes.incrementTokenVerificationAttempts
  };
}

export function setTokenVerificationCooldown() {
  return {
    type: actionTypes.setTokenVerificationCooldown
  };
}

export function resetTokenVerificationAttempts() {
  return {
    type: actionTypes.resetTokenVerificationAttempts
  };
}

export function showMaxAttemptsErrorDialog() {
  return {
    type: actionTypes.showMaxAttemptsErrorDialog
  };
}

export function getPhoneVerificationToken(phoneNumber) {
  let endpoint = `utils/getPhoneToken/${phoneNumber}`;
  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.getTokenVerificationRequest, actionTypes.getTokenVerificationSuccess, actionTypes.getTokenVerificationFailure]
    }
  };

}

export function validatePhoneToken(params) {
  let endpoint = 'utils/phoneVerification';
  const body = {
    phoneNumber: params.phoneNumber,
    token: params.token
  };
  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.phoneVerificationRequest, actionTypes.phoneVerificationSuccess, actionTypes.phoneVerificationFailure],
      body
    }
  };
}

// Sign in methods
export function getTwoFactorToken(user) {
  const queryString = '?action=requesttoken&force=true';
  const endpoint = `users/accounts/${user.selectedMerchantAccount}/twofactor${queryString}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.twoFactorSendTokenRequest, actionTypes.twoFactorSendTokenSuccess, actionTypes.twoFactorSendTokenFailure]
    }
  };
}

export function verifyTwoFactorToken(user, token) {
  const queryString = `?action=validatetoken&token=${token}`;
  const endpoint = `users/accounts/${user.selectedMerchantAccount}/twofactor${queryString}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.twoFactorVerifyTokenRequest, actionTypes.twoFactorVerifyTokenSuccess, actionTypes.twoFactorVerifyTokenFailure]
    }
  };
}

export function getPhoneToken(user) {

  const endpoint = `users/${user.data.user_id}/accounts/${user.selectedMerchantAccount}/twofactor`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: false,
      types: [actionTypes.twoFactorPhoneCodeRequest, actionTypes.twoFactorPhoneCodeSuccess, actionTypes.twoFactorPhoneCodeFailure]
    }
  };
}

export function validateSignInPhoneToken(user) {

  const queryString = `?token=${user.code}`;

  const config = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'requestDate': dayjs().format('ddd, DD MMM YYYY HH:mm:ss Z'),
      'csrf-token': helperGetCSRFCookieToken()
    },
    body: `username=${encodeURIComponent(user.email)}&password=${encodeURIComponent(btoa(user.password))}`
  };

  return async dispatch => {

    dispatch(phoneVerifyRequest());

    const url = `${serverBaseUrl}users/create-session-twofactor${queryString}`;

    try {
      const payload = await (await fetch(url, config)).json();

      if (payload.token && payload.user) {
        localStorage.setItem('pa-apply-token', payload.token);
        localStorage.setItem('pa-apply-u', JSON.stringify(payload.user));
        dispatch(phoneVerifySuccess(payload));
        return Promise.resolve(payload);
      } else {
        dispatch(phoneVerifyError(payload.message));
        dispatch(incrementTokenVerificationAttempts());
        return Promise.reject(payload);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function closeTwoFactorErrorDialog() {
  return {
    type: actionTypes.closeTwoFactorErrorDialog
  };
}

export function toggleShowInvalidRoutingDialog(show) {
  return {
    type: actionTypes.showInvalidRoutingDialog,
    value: show
  };
}
