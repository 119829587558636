/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import emailValidator from 'email-validator';
import _ from 'lodash';
import errorMessages from '../constants/messages';
import CommonUtil from './CommonUtil';
import FormatTextUtil from './FormatTextUtil';
import dayjs from '../config/DayjsConfig';

const Validator = {

  messages: errorMessages.validator,

  hasNoValue: function (value) {
    return !value;
  },

  areEqual: function (value1, value2) {
    return value1 === value2;
  },

  isPhoneNumber: function (phoneNumber) {

    const phoneNumberRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

    return phoneNumberRegEx.test(phoneNumber);

  },

  isDate: (value) => {
    const dateRegEx = /^(0[1-9]|1[0-2])[\/|-](0[1-9]|[12][0-9]|3[01])[\/|-]([1-2][0-9]{3})$/;
    return dateRegEx.test(value);
  },

  ageValidation: (value) => {
    const dob = dayjs(value).utc();
    const today = dayjs().utc();
    const age = today.diff(dob, 'years');
    return (age >= 18);
  },

  isCardDate: (value) => {
    const dateRegEx = /^(0[1-9]|1[0-2])[/|-]([0-9][0-9])$/;
    return dateRegEx.test(value);
  },

  isSSNValid: (value) => {

    const ssnRegex = /^\d{4}$/;
    return ssnRegex.test(value) && value !== '0000';
  },

  isABAValid: (value) => {
    const regEx = /^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$/;
    return regEx.test(value);
  },

  isZipValid: (value) => {

    const zipRegex = /^(\d{5}(,\d{5})*)$/;
    return zipRegex.test(value);
  },

  isFullSsnOrTinValid: (value) => {
    /*
    Special numbers
    Numbers with all zeros in any digit group (000-##-####, ###-00-####, ###-##-0000).
    Numbers with 666 or 900-999 (Individual Taxpayer Identification Number) in the first digit group.
    */
    const ssnRegex = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
    return ssnRegex.test(value);
  },
  invalidSsnNumber: (value) => {
    const ssnRegex = /^(\d)\1{2}-\1{2}-\1{4}$|^(123-45-6789)$/
    return ssnRegex.test(value);
  },
  isFederalTaxIdValid: (value) => {
    const fedTaxIdRegex = /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/;
    return fedTaxIdRegex.test(value);

  },

  validPassword: (value) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])((?=.*\d)|(?=.*[~!@#$%^&*()-=+]))[A-Za-z\d~!@#$%^&*()-=+]{8,}$/;
    return passwordRegex.test(value);
  },

  validAccountNumber: (number) => {
    return number.length > 3 && number.length < 18 && number.length !== 16 && !isNaN(number);
  },

  validWebsite: (website) => {
    const expression = /^((http|https):\/\/)(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
    const regexp = new RegExp(expression);
    return regexp.test(website);
  },

  validDbaName: (name) => {
    const nameRegex = /^\S.*$/gm;
    return nameRegex.test(name);
  },

  validFaxNumber: (fax) => {
    const faxRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    return faxRegex.test(fax);
  },

  isMobile: (userAgent) => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  },
  isAndroidDevice: (userAgent) => {
    return /Android/i.test(userAgent);
  },
  validateClientSignUpForm: function (values) {

    let errors = {};
    let that = this;

    const requiredFields = [
      'email_address',
      'password',
      'confirm_password',
      'customer_first_name',
      'customer_last_name',
      'customer_home_address',
      'customer_city',
      'customer_state',
      'customer_zip_code',
      'customer_telephone',
      'customer_ssn',
      'customer_date_of_birth',
      'customer_title',
      'customer_equity_ownership'
    ];

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values.customer_last_name && values.customer_last_name.length < 2) {
      errors.customer_last_name = that.messages.invalidLastName;
    }

    if (values.email_address && !emailValidator.validate(values.email_address)) {
      errors.email_address = that.messages.invalidEmail;
    }

    if (!this.isPhoneNumber(values.customer_telephone)) {
      errors.customer_telephone = that.messages.invalidPhoneNumber;
    }

    if (!this.isSSNValid(values.customer_ssn)) {
      errors.customer_ssn = that.messages.invalidText;
    }

    if (!this.isDate(values.customer_date_of_birth)) {
      errors.customer_date_of_birth = that.messages.invalidDate;
    }

    if (!this.ageValidation(values.customer_date_of_birth) && this.isDate(values.customer_date_of_birth)) {
      errors.customer_date_of_birth = that.messages.invalidDOB;
    }

    if (!this.isZipValid(values.customer_zip_code)) {
      errors.customer_zip_code = that.messages.zipRequired;
    }

    if (values.password && !this.validPassword(values.password)) {
      errors.password = that.messages.invalidPassword;
    }
    if (!this.areEqual(values.password, values.confirm_password)) {
      errors.confirm_password = that.messages.passwordsDoNotMatch;
    }

    if (values.customer_equity_ownership > 100) {
      errors.customer_equity_ownership = that.messages.customerEquityOwnershipMore;
    }

    if (!(values.owners && values.owners.length) && values.customer_equity_ownership < 100) {
      errors.customer_equity_ownership = that.messages.customerEquityOwnershipLess;
    }

    if (values.customer_state && values.customer_state.length > 2) {
      errors.customer_state = that.messages.invalidValue;
    }

    const ownersRequiredFields = [
      'first_name',
      'last_name',
      'title',
      'equity_ownership',
      'telephone',
      'ssn',
      'date_of_birth',
      'email_address',
      'home_address',
      'city',
      'state',
      'zip_code'
    ];

    if (values.owners && values.owners.length) {
      const ownersArrayErrors = [];

      let ownerEquity = CommonUtil.calculateTotal(values.owners, 'equity_ownership');

      ownerEquity += parseInt(values.customer_equity_ownership);

      values.owners.forEach((owner, ownerIndex) => {
        const ownerErrors = {};
        _.map(ownersRequiredFields, (field) => {
          if (!owner || !owner[field]) {
            ownerErrors[field] = that.messages.requiredText;
          }
        });

        if (ownerEquity > 100 || values.customer_equity_ownership === 100) {
          errors.customer_equity_ownership = that.messages.customerEquityOwnershipMore;
          ownerErrors.equity_ownership = that.messages.customerEquityOwnershipMore;
        }

        if (ownerEquity < 100) {
          errors.customer_equity_ownership = that.messages.customerEquityOwnershipLess;
          ownerErrors.equity_ownership = that.messages.customerEquityOwnershipLess;
        }

        if (owner && owner.state && owner.state.length > 2) {
          ownerErrors.state = that.messages.invalidValue;
        }

        if (owner && !(_.isEmpty(owner.ssn)) && !this.invalidSsnNumber(owner.ssn)) {
          ownerErrors.ssn = that.messages.invalidSsnNumber;
        }

        if (owner && !(_.isEmpty(owner.ssn)) && !this.isFullSsnOrTinValid(owner.ssn)) {
          ownerErrors.ssn = that.messages.invalidText;
        }

        if (owner && !this.isDate(owner.date_of_birth)) {
          ownerErrors.date_of_birth = that.messages.invalidDate;
        }

        if (owner && !this.ageValidation(owner.date_of_birth) && this.isDate(owner.date_of_birth)) {
          ownerErrors.date_of_birth = that.messages.invalidDOB;
        }

        ownersArrayErrors[ownerIndex] = ownerErrors;

      });

      errors.owners = ownersArrayErrors;

    }
    if (values.customer_state && values.customer_state.length > 2) {
      errors.customer_state = that.messages.invalidValue;
    }

    return errors;
  },
  validateBusinessSignUpForm: function (values) {

    let errors = {};
    let that = this;
    let totalBusinessPercent = 0;

    const requiredFields = [
      'DBA_name',
      'corporate_business_name',
      'business_telephone',
      'business_email',
      'legal_business_structure',
      'business_type',
      'industry',
      'business_description',
      'ticket_amount',
      'monthly_volume',
      'bank_routing_number',
      'account_number',
      'account_type',
      'business_street_address',
      'business_city',
      'business_zip_code',
      'business_state'
    ];

    const validateWebsite = values.business_type === 'Internet' ||
      (values.business_type === 'Restaurant' && values.internet_percent_business > 0) ||
      (values.business_type === 'Retail' && values.internet_percent_business > 0);

    [values.card_present_percent_business,
      values.keyed_percent_business,
      values.moto_percent_business,
      values.internet_percent_business].forEach((value) => {
      if (value !== '') {
        totalBusinessPercent += value;
      }
    });

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (!this.validDbaName(values.DBA_name)) {
      errors.DBA_name = that.messages.invalidDbaName;
    }

    if (!this.isPhoneNumber(values.business_telephone)) {
      errors.business_telephone = that.messages.invalidPhoneNumber;
    }

    if (values.card_expiration_date && !this.isCardDate(values.card_expiration_date)) {
      errors.card_expiration_date = that.messages.invalidDate;
    }

    if (values.bank_routing_number && !this.isABAValid(values.bank_routing_number)) {
      errors.bank_routing_number = that.messages.abaInvalidNumber;
    }

    if (values.account_number && !this.validAccountNumber(values.account_number)) {
      errors.account_number = that.messages.invalidAccountNumber;
    }

    if (values.business_email && !emailValidator.validate(values.business_email)) {
      errors.business_email = that.messages.invalidEmail;
    }

    if (!this.isFederalTaxIdValid(values.federal_tax_id)) {
      errors.federal_tax_id = that.messages.invalidTaxId;
    }

    if (!this.isZipValid(values.business_zip_code)) {
      errors.business_zip_code = that.messages.zipRequired;
    }

    if (values.customer_ssn !== undefined && this.invalidSsnNumber(values.customer_ssn)) {
      errors.customer_ssn = that.messages.invalidSsnNumber;
    }

    if (values.customer_ssn !== undefined && !this.isFullSsnOrTinValid(values.customer_ssn)) {
      errors.customer_ssn = that.messages.invalidText;
    }

    if (values.customer_date_of_birth && !this.isDate(values.customer_date_of_birth)) {
      errors.customer_date_of_birth = that.messages.invalidDate;
    }

    if (values.customer_date_of_birth && (!this.ageValidation(values.customer_date_of_birth) && this.isDate(values.customer_date_of_birth))) {
      errors.customer_date_of_birth = that.messages.invalidDOB;
    }

    if (validateWebsite && !this.validWebsite(values.website)) {
      errors.website = that.messages.invalidWebsite;
    }

    if (values.fax_number && !this.validFaxNumber(values.fax_number)) {
      errors.fax_number = that.messages.invalidFaxNumber;
    }

    if (values.business_state && values.business_state.length > 2) {
      errors.business_state = that.messages.invalidValue;
    }

    if (values.corporate_business_name && values.corporate_business_name.length > 30) {
      errors.corporate_business_name = that.messages.invalidCorporateName;
    }



    if (totalBusinessPercent < 100 || totalBusinessPercent > 100) {
      errors.card_present_percent_business = that.messages.invalidPercent;
      errors.keyed_percent_business = that.messages.invalidPercent;
      errors.moto_percent_business = that.messages.invalidPercent;
      errors.internet_percent_business = that.messages.invalidPercent;
    }

    return errors;
  },
  validateClientSignUpFormPopUp: function (values) {

    let errors = {};
    let that = this;

    const requiredFields = [
      'email_address',
      'first_name',
      'last_name',
      'telephone'
    ];

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values.last_name && values.last_name.length < 2) {
      errors.last_name = that.messages.invalidLastName;
    }

    if (values.email_address && !emailValidator.validate(values.email_address)) {
      errors.email_address = that.messages.invalidEmail;
    }

    if (!this.isPhoneNumber(values.telephone)) {
      errors.telephone = that.messages.invalidPhoneNumber;
    }

    return errors;
  },
  validateVerifySSNForm: function (values) {
    let errors = {};
    let that = this;

    if (that.hasNoValue(values.customer_ssn)) {
      errors.customer_ssn = that.messages.requiredText;
    }

    if (!(_.isEmpty(values.customer_ssn)) && this.invalidSsnNumber(values.customer_ssn)) {
      errors.customer_ssn = that.messages.invalidSsnNumber;
    }

    if (!(_.isEmpty(values.customer_ssn)) && !this.isFullSsnOrTinValid(values.customer_ssn)) {
      errors.customer_ssn = that.messages.invalidText;
    }
    if (twoFactorAuth && this.hasNoValue(values.phone_token)) {
      errors.phone_token = that.messages.requiredText;
    }
    return errors;
  },
  validateAddFile: function (values) {
    let errors = {};
    let that = this;
    if (values.files) {
      values.files.map((file, index) => {
        if (file.rejected) {
          errors[`files.${index}.name`] = that.messages.invalidFile;
        }
      });

    }
    return errors;
  },

  validatePaymentForm: function (values) {

    const errors = {};
    const cardType = FormatTextUtil.parseCardType(values.cdigits);

    if (this.hasNoValue(values['cdigits'])) {
      errors.cdigits = this.messages.creditCardRequired;
    }

    if (this.hasNoValue(values['edate'])) {
      errors.edate = this.messages.expirationDateRequired;
    } else {

      const sections = values['edate'].split('/');
      if (sections.length !== 2) {
        errors.edate = this.messages.expirationDateInvalid;
      }

      const year = parseInt(sections[1], 10),
        month = parseInt(sections[0], 10);

      if (month <= 0 || month > 12 || year > 99 || year < 0) {
        errors.edate = this.messages.expirationDateInvalid;
      }
    }

    if (this.hasNoValue(values['cvv'])) {
      errors.cvv = this.messages.securityCodeRequired;
    }

    if (values.cvv && values.cvv.length < 3 && cardType !== 'amex') {
      errors.cvv = this.messages.securityCodeShouldBeThreeDigits;
    }

    if (values.cvv && values.cvv.length < 4 && cardType === 'amex') {
      errors.cvv = this.messages.securityCodeShouldBeFourDigits;
    }


    if (this.hasNoValue(values['street_number'])) {
      errors.street_number = this.messages.streetNumberRequired;
    }

    if (this.hasNoValue(values['zip'])) {
      errors.zip = this.messages.zipRequired;
    }

    if (values['zip'] && values['zip'].length !== 5) {
      errors.zip = this.messages.zipShouldBeFiveDigits;
    }

    if (values['zipPlus4'] && values['zipPlus4'].length !== 4) {
      errors.zipPlus4 = this.messages.zipPlusFourShouldBeFourDigits;
    }

    if (values['zipPlus4'] && values['zipPlus4'].length === 4 && this.hasNoValue(values['zip'])) {
      errors.zip = this.messages.zipRequiredWithZipPlusFour;
    }

    return errors;
  },

  requiredValue: (value) => {
    return value ? null : errorMessages.validator.requiredText;
  },
  validateSignInFormPopUp: function (values) {

    let errors = {};
    let that = this;

    const requiredFields = [
      'email_address',
      'password'
    ];

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values.email_address && !emailValidator.validate(values.email_address)) {
      errors.email_address = that.messages.invalidEmail;
    }

    if (values.password && !this.validPassword(values.password)) {
      errors.password = that.messages.invalidPassword;
    }

    return errors;
  },

  validateTwoFactorVerificationForm: function (values) {
    const errors = {};
    if (this.hasNoValue(values.validationCode)) {
      errors.validationCode = this.messages.requiredText;
    }
    return errors;
  },
  validateTwoFactorForm: function (values) {
    const errors = {};
    const requiredFields = ['cellPhone'];

    requiredFields.map((field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = this.messages.requiredText;
      }
    });

    if (values['cellPhone'] && !this.isPhoneNumber(values['cellPhone'])) {
      errors['cellPhone'] = this.messages.invalidMobileNumber;
    }

    return errors;
  },
};

export default Validator;
