/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import TextField from '../Shared/Forms/TextField';
import Validator from '../../util/Validator';
import FormatTextUtil from '../../util/FormatTextUtil';
import VisibilityToggle from '../../util/VisibilityToggle';

const validate = (values) => {
  return Validator.validatePaymentForm(values);
};

class PaymentForm extends Component {

  state = {
    maskedInput: false
  };

  toggleCardNumberVisibility = () => {
    this.setState(prevState => ({
      maskedInput: !prevState.maskedInput
    }));
  };

  render () {
    const { onSubmit, virtualTerminal, isDisabled } = this.props;
    return (
      <Form
        onSubmit={onSubmit}
        validate={validate}
      >
        {
          ({ handleSubmit }) => {
            return (
              <form
                id='paymentForm'
                className='paymentForm'
                onSubmit={handleSubmit}
              >
                <div className='fieldsWrapper'>
                  <div className='formRow'>
                    <div className='formInput stacked cardNumber'>
                      <div className='cardContainer'>
                        <Field
                          type={this.state.maskedInput ? 'password' : 'text'}
                          label='Credit card number'
                          component={TextField}
                          name='cdigits'
                          parse={FormatTextUtil.formatCardNumber}
                          placeholder={'0000-0000-0000-0000'}
                          fullWidth
                          disabled={isDisabled}
                          inputProps={{
                            maxLength: 25
                          }}
                        />
                      </div>
                      <div className='cardVisibilityContainer'>
                        <VisibilityToggle
                          visibility={this.state.maskedInput}
                          onClick={this.toggleCardNumberVisibility}
                          className='visibilityToggle'
                        />
                      </div>
                    </div>
                    <div className='formRow stacked'>
                      <div className='formInput'>
                        <Field
                          label='Expiration'
                          component={TextField}
                          name='edate'
                          parse={FormatTextUtil.formatCreditCardExpiration}
                          placeholder={'MM/YY'}
                          fullWidth
                          disabled={isDisabled}
                        />
                      </div>
                      <div className='formInput'>
                        <Field
                          label={virtualTerminal.isAmex ? 'CID ' : 'CVV'}
                          component={TextField}
                          name='cvv'
                          parse={FormatTextUtil.formatWholeNumber}
                          placeholder={virtualTerminal.isAmex ? '0000' : '000'}
                          fullWidth
                          disabled={isDisabled}
                          inputProps={{
                            maxLength: 10
                          }}
                        />
                      </div>
                      <div className='formInput cardImage'>
                        {virtualTerminal.cardType ?
                          <img
                            src={`${serverDomainUrl}images/cards/${virtualTerminal.cardType}.png`}/>
                          : <img
                            src={`${serverDomainUrl}images/cards/unknown_card.png`}/>}
                      </div>
                    </div>
                  </div>
                  <div className='formRow'>
                    <div className='formInput stacked'>
                      <Field
                        label='Street number'
                        component={TextField}
                        name='street_number'
                        fullWidth
                        disabled={isDisabled}
                      />
                    </div>
                    <div className='formRow stacked'>
                      <div className='formInput'>
                        <Field
                          label='Zip'
                          component={TextField}
                          name='zip'
                          parse={FormatTextUtil.formatZip}
                          fullWidth
                          disabled={isDisabled}
                        />
                      </div>
                      <div className='formInput'>
                        <Field
                          label='Zip+4(optional)'
                          component={TextField}
                          name='zipPlus4'
                          parse={FormatTextUtil.formatOptionalZip}
                          fullWidth
                          disabled={isDisabled}
                        />
                      </div>
                      <div className='formInput cardImage'/>
                    </div>
                  </div>
                </div>
              </form>
            );
          }
        }
      </Form>
    );
  }
}

export default PaymentForm;
