/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  changeStep,
  closeBusinessSignUpFailureDialog, showUnexpectedErrorDialog
} from '../../actions/userExperienceActions';
import { postEquipmentOrder } from '../../actions/shoppingBagActions';
import {
  executeFinalizeApplicationTaskRequest,
  updatesVerificationFlags
} from '../../actions/applicationActions';
import { logoutUser } from '../../actions/authActions';
import { verification } from '../../actions/verificationActions';

import UhOhDialog from '../Shared/Dialog/UhOhDialog';
import CircleButton from '../Shared/Button/CircleButton';
import Header from '../Shared/Header/Header';
import Footer from '../Shared/Footer/index';
import TermsText from './TermsText';
import BackdropLoader from '../Shared/BackdropLoader'

import ShoppingBagUtil from '../../util/ShoppingBagUtil';
import routes from '../../constants/routes';
import userDirections from '../../constants/userDirections';
import {compose} from 'redux';
import WithNavigation from '../../hoc/withNavigation';
import messages from '../../constants/messages';

class Terms extends Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.logoutAndRedirect = this.logoutAndRedirect.bind(this);
  }

  state = {
    exhibitACollapsed: true,
    userAgreementAccepted: false,
    pricingAgreementAccepted: false
  };

  async componentDidMount() {

    if (!this.props.auth.isAuthenticated) {
      await this.logoutAndRedirect();
    } else {
      this.props.changeStep('terms', this.props.userExperience.steps);
    }
  }

  logoutAndRedirect = async () => {
    await this.props.logoutUser();
    await this.props.changeLocation(routes.home);
  };

  async handleSubmit() {
    if (this.props.getApplicationId() === null) {
      await this.logoutAndRedirect();
      return this.props.showUnexpectedErrorDialog(true);
    }

    const subtotal = Number(ShoppingBagUtil.getSubtotal(this.props));
    const shipFreeReader = this.props.shoppingBag.bag.filter((product) => product.equipmentId === 791 && product.quantity > 0);
    if (Object.keys(this.props.fraudVerification.verification).length) {
      const verification = await this.props.verification(this.props.fraudVerification.verification);
      const verificationValues = {
        isTwoFactorAuthenticated: this.props.fraudVerification.verification.twoFactorAuthenticated,
        isVerified: verification?.response?.data?.verificationResult || false
      }
      await this.props.updatesVerificationFlags(verificationValues);
    }

    await this.props.finalizeApplication(this.props.businessSignUp.giactValidationPassed)

    if (this.props.application.status === 'finalized') {
      if (subtotal !== 0) {
        this.props.closeBusinessSignUpFailureDialog();
        this.props.changeLocation(routes.payment);
      }

      if (subtotal === 0 && shipFreeReader) {
        const shoppingBagItems = this.props.shoppingBag.bag.filter((item) => item.quantity > 0);
        this.props.postEquipmentOrder(this.props.user, shoppingBagItems, null, this.props.application.statusID);
        this.props.changeLocation(routes.thankYou);
      }
    }
  };

  goBack = () => {
    const { userExperience } = this.props;
    this.props.changeStep('shoppingBag', userExperience.steps);
    this.props.changeLocation(routes.home);
  };

  toggleState = (key) => {
    this.setState(prevState => ({
      [key]: !prevState[key]
    }));
  };

  toggleUACheckbox = () => this.toggleState('userAgreementAccepted');
  togglePACheckbox = () => this.toggleState('pricingAgreementAccepted');
  toggleExhibitA = () => this.toggleState('exhibitACollapsed');

  render() {
    const invalidRoutingNumberError = this.props.application.finalizeApplicationError === messages.errors.invalidRoutingNumber
    const isProcessing = this.props.application.executing;

    const processBusinessSignUpFailureDialog = !!this.props.userExperience.showProcessBusinessSignUpFailureDialog && (
      <UhOhDialog
        messageType={invalidRoutingNumberError ? 'invalidRoutingNumber' : 'applicationFailed'}
        closeAction={this.props.closeBusinessSignUpFailureDialog}
        backRoute={'businessSignUp'}
        {...this.props}
      />
    );
    const checkboxContainer = (<div className='checkboxes'>
      <div className='userAgreement'>
        <CircleButton onClick={this.toggleUACheckbox} />
        <div className='text'>{userDirections.terms.agreeUser}</div>
      </div>
      <div className='pricing'>
        <CircleButton onClick={this.togglePACheckbox} />
        <div className='text'>
          <span>{userDirections.terms.agreePricing}</span>
          <a href='/docs/pa-pricing-agreement.pdf' target='_blank'
            className='pdf'>{'View PDF'}</a>
        </div>
      </div>
    </div>);

    return (
      <>
        <BackdropLoader show={isProcessing}/>
        <div className='termsPage'>
        <Header steps={this.props.userExperience.steps} />
        <div className='terms'>
          <div className='introText'>
            <h1>{userDirections.terms.title}</h1>
          </div>
          <div className='terms-container'>
            <TermsText
              exhibitACollapsed={this.state.exhibitACollapsed}
              toggleExhibitA={this.toggleExhibitA}
            />
          </div>
          {processBusinessSignUpFailureDialog}
        </div>
        <Footer
          backAction={this.goBack}
          buttonAction={this.handleSubmit}
          buttonLabel={isProcessing ? 'Submitting' : 'Submit'}
          buttonDisabled={isProcessing || !(this.state.userAgreementAccepted && this.state.pricingAgreementAccepted)}
          sideComponent={checkboxContainer}
          termsFooter
        />
      </div>
      </>
    );
  }
}

function mapStateToProps(state) {

  const {
    application,
    routing,
    user,
    userExperience,
    equipment,
    accessories,
    shoppingBag,
    auth,
    fraudVerification,
    businessSignUp
  } = state;

  return {
    application: application,
    notifications: state.userExperience.notifications,
    routing: routing,
    user: user,
    userExperience: userExperience,
    shoppingBag: shoppingBag,
    equipment: equipment,
    accessories: accessories,
    auth,
    fraudVerification,
    businessSignUp
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {

  return {

    changeStep: (page, pages) => {
      dispatch(changeStep(page, pages));
    },

    logoutUser: () => {
      dispatch(logoutUser());
    },

    closeBusinessSignUpFailureDialog: () => {
      dispatch(closeBusinessSignUpFailureDialog());
    },

    postEquipmentOrder: (user, shoppingBagItems, shipping, statusId) => {
      dispatch(postEquipmentOrder(user, shoppingBagItems, shipping, statusId));
    },

    finalizeApplication: async (giactValidationPassed) => {

      try {
        const finalizeApplication = await dispatch(executeFinalizeApplicationTaskRequest(giactValidationPassed));
        return ({ response: finalizeApplication });
      } catch (error) {
        return Promise.reject(`Failed to finalize the application: ${error.message}`);
      }
    },
    verification: values => dispatch(verification(values)),
    changeLocation: route => ownProps.navigate(route),
    updatesVerificationFlags: values => dispatch(updatesVerificationFlags(values)),
    getApplicationId: () => localStorage.getItem('pa-apply-application-id'),
    showUnexpectedErrorDialog: (display) => dispatch(showUnexpectedErrorDialog(display))
  };
};
export default compose(
  WithNavigation,
  connect(mapStateToProps, mapDispatchToProps)
)(Terms);
