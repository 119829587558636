/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import _ from 'lodash';

import TextField from '../Shared/Forms/TextField';
import SelectField from '../Shared/Forms/SelectField';
import RadioGroup from '../Shared/Forms/RadioGroup';
import AdditionalOwnerWrapper from '../ClientData/AdditionalOwnerWrapper';
import AddressForm from './AddressForm';
import Validator from '../../util/Validator';
import TextUtil from '../../util/FormatTextUtil';
import formIds from '../../constants/formIds';

const validate = values => Validator.validateClientSignUpForm(values);

class ClientSignUpForm extends Component {

  constructor (props) {
    super(props);
    this.isMobile = this.props.isMobile || Validator.isMobile(navigator.userAgent);

    this.statesOptions = this.props.statesOptions.map(option => ({
      label: option.description,
      value: option.code
    }));

    this.titleOptions = this.props.titleOptions.map(option => ({
      label: option,
      value: option
    }));

    this.submithandler = null;

  }

  render () {
    const { onSubmit, isDisabled, initialValues } = this.props;
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        mutators={{
          ...arrayMutators
        }}
      >
        {({ handleSubmit }) => {
          this.submithandler = handleSubmit;
          return (
            <form onSubmit={handleSubmit}
                  id={formIds.clientSignUpForm}
                  className='clientFormContainer'
            >
              <div className='fieldsWrapper'>
                <div className='formRow'>
                  <div className='formInput'>
                    <Field
                      className='addBorder'
                      label='First name'
                      component={TextField}
                      name='customer_first_name'
                      margin='normal'
                      variant='outlined'
                      fullWidth
                      disabled={isDisabled}
                    />
                  </div>
                  <div className='formInput'>
                    <Field
                      className='addBorder'
                      label='Last name'
                      component={TextField}
                      name='customer_last_name'
                      fullWidth
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className='formRow'>
                  <div className='formInput'>
                    <Field
                      name='customer_title'
                      id='customer_title'
                      component={SelectField}
                      fullWidth
                      label='Title'
                      options={this.titleOptions}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className='formInput'>
                    <Field
                      className='addBorder'
                      label='Equity ownership'
                      helperText={'Values represent percentages'}
                      component={TextField}
                      name='customer_equity_ownership'
                      parse={TextUtil.formatEquityOwnership}
                      fullWidth
                      disabled={isDisabled}
                      placeholder='00%'
                    />
                  </div>
                </div>
                <div className='formRow'>
                  <div className='formInput stacked'>
                    <Field
                      label='Email'
                      type='email'
                      component={TextField}
                      name='email_address'
                      fullWidth
                      disabled={isDisabled}
                    />
                  </div>
                  <div className='formRow stacked'>
                    <div className='formInput'>
                      <Field
                        label='Create password'
                        component={TextField}
                        name='password'
                        type='password'
                        fullWidth
                        disabled={isDisabled}
                      />
                    </div>
                    <div className='formInput'>
                      <Field
                        label='Confirm password'
                        component={TextField}
                        name='confirm_password'
                        type='password'
                        fullWidth
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                </div>
                <div className='formRow'>
                  <div className='formInput stacked'>
                    <Field
                      label='Mobile number'
                      placeholder='(000) 000-0000'
                      type='tel'
                      component={TextField}
                      name='customer_telephone'
                      parse={TextUtil.formatPhoneNumber}
                      fullWidth
                      disabled={isDisabled}
                    />
                  </div>
                  <div className='formRow stacked'>
                    <div className='formInput'>
                      <Field
                        label='Last 4 SSN'
                        component={TextField}
                        name='customer_ssn'
                        parse={TextUtil.formatSSN}
                        fullWidth
                        disabled={isDisabled}
                        placeholder='0000'
                      />
                    </div>
                    <div className='formInput'>
                      <Field
                        label='Date of birth'
                        placeholder='MM/DD/YYYY'
                        component={TextField}
                        format={TextUtil.formatDOB}
                        name='customer_date_of_birth'
                        fullWidth
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                </div>
                <AddressForm
                  {...this.props}
                  statesOptions={this.statesOptions}
                  address='customer_home_address'
                  secondAddress='customer_apartment'
                  city='customer_city'
                  state='customer_state'
                  zipCode='customer_zip_code'
                  streetLabel='Home address'
                  secondStreetLabel='Suite or apt.'
                />
                <div className='formRow'>
                  <div className='formInput'>
                    <Field
                      name='primary_owner'
                      component={RadioGroup}
                    >
                      <FormControlLabel
                        value='owner_0'
                        control={<Radio/>}
                        label='This owner is the primary contact'
                        classes={{ label: 'radioLabel' }}
                      />
                    </Field>
                  </div>

                </div>
                <FieldArray
                  name='owners'
                  component={AdditionalOwnerWrapper}
                  titleOptions={this.titleOptions}
                  statesOptions={this.statesOptions}
                  disabled={isDisabled}
                />
              </div>
            </form>
          );
        }
        }</Form>
    );
  }
}

function mapStateToProps (state, ownProps) {

  const initialValues = {};
  let cookies = document.cookie.split('; ');

  /* istanbul ignore next */
  _.each(cookies, (cookie) => {
    if (cookie.includes('visitor_id321901=')) {
      initialValues.visitor_id = cookie.replace('visitor_id321901=', '');
    }
  });

  const profile = _.get(state.accountProfile, 'data', {});

  initialValues.email_address = state.clientSignUp.dialogData?.email ? state.clientSignUp.dialogData.email : null;
  initialValues.password = null;
  initialValues.confirm_password = null;
  initialValues.customer_first_name = state.clientSignUp.dialogData?.first_name ? state.clientSignUp.dialogData.first_name : null;
  initialValues.customer_last_name = state.clientSignUp.dialogData?.last_name ? state.clientSignUp.dialogData.last_name : null;
  initialValues.customer_home_address = null;
  initialValues.customer_apartment = null;
  initialValues.customer_zip_code = null;
  initialValues.customer_telephone = state.clientSignUp.dialogData?.phone ? state.clientSignUp.dialogData.phone : null;
  initialValues.customer_city = null;
  initialValues.customer_state = null;
  initialValues.customer_ssn = null;
  initialValues.customer_date_of_birth = null;
  initialValues.customer_title = null;
  initialValues.is_verified_address = false;
  initialValues.primary_owner = 'owner_0';

  return {
    initialValues,
    profile
  };

}

ClientSignUpForm = connect(mapStateToProps, null, null, { forwardRef: true })(ClientSignUpForm);
export default ClientSignUpForm;
