export default {
  terms: {
    title: 'Review user agreement.',
    agreeUser: 'I have reviewed and agree to the User Agreement.',
    agreePricing: 'I agree to the Pricing.'
  },
  payment: {
    title: 'Enter payment information.'
  },
  thankYou: {
    title: 'Thanks for applying \n with Payanywhere.',
    emailEnRoute: 'An email notification regarding your application status is on the way. If you have not received a ' +
      'notification within 24 hours, you may contact Customer Service.',
    IncodeDisclaimer: 'Note: If we are unable to verify your identity, you may receive a standard SMS from PaymentsHub/North American Bancard requesting that you complete identity verification.'
  }
};

