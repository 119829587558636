import React from 'react';

export default function ArrowRightIcon({style}) {
  return (
    <svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'
         viewBox='0 0 32 32' xmlSpace='preserve'
         style={style}
         className='arrowRight'
    >
      <path id='_Path_' d='M27.5,18.3c0.7-1.1,0.7-2.4,0-3.5C25,10.9,21.7,7.5,17.7,5c-1.5-1.1-3.7-0.7-4.8,0.8
	s-0.7,3.7,0.8,4.8c0.2,0.1,0.3,0.2,0.5,0.3c0.8,0.5,1.5,1,2.2,1.6l-9.8,0.8c-1.8,0.1-3.1,1.6-3,3.4c0.1,1.6,1.4,2.9,3,3l10,0.8
	c-0.7,0.6-1.5,1.2-2.3,1.7c-1.6,0.9-2.2,3-1.3,4.7s3,2.2,4.7,1.3l0.1-0.1C21.7,25.6,25.1,22.2,27.5,18.3z'
      />
    </svg>

  );

}
