import React from 'react';

export default function WarningIcon({style}) {
  return (
    <svg className='warningIcon' style={style} xmlns='http://www.w3.org/2000/svg' height='24' width='24' viewBox='0 0 48 48'>
      <path d='M24.15 26.35Q24.9 26.35 25.375 25.875Q25.85 25.4 25.85 24.65V15.15Q25.85 14.5 25.35 14Q24.85 13.5 24.15 13.5Q23.4 13.5 22.925 14Q22.45 14.5 22.45 15.2V24.7Q22.45 25.4 22.95 25.875Q23.45 26.35 24.15 26.35ZM24 34.3Q24.8 34.3 25.35 33.75Q25.9 33.2 25.9 32.4Q25.9 31.6 25.375 31.025Q24.85 30.45 24 30.45Q23.2 30.45 22.65 31.025Q22.1 31.6 22.1 32.4Q22.1 33.2 22.625 33.75Q23.15 34.3 24 34.3ZM24 44.3Q19.7 44.3 15.975 42.75Q12.25 41.2 9.525 38.475Q6.8 35.75 5.25 32.025Q3.7 28.3 3.7 24Q3.7 19.75 5.25 16.025Q6.8 12.3 9.525 9.575Q12.25 6.85 15.975 5.275Q19.7 3.7 24 3.7Q28.25 3.7 31.975 5.275Q35.7 6.85 38.425 9.575Q41.15 12.3 42.725 16.025Q44.3 19.75 44.3 24Q44.3 28.3 42.725 32.025Q41.15 35.75 38.425 38.475Q35.7 41.2 31.975 42.75Q28.25 44.3 24 44.3Z'/>
    </svg>

  );

}
