/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {compose} from 'redux';

import WithNavigation from '../../hoc/withNavigation';
import { syncBag, updateItemQuantity } from '../../actions/shoppingBagActions';
import { getEquipment } from '../../actions/equipmentActions';
import { getAccessories } from '../../actions/accesoryActions';
import {
  getNABItems,
  changeStep,
  showUnexpectedErrorDialog,
  showSignInTwoFactorAuthenticationDialog,
  closeMeapiErrorDialog
} from '../../actions/userExperienceActions';
import {
  closeLoginErrorDialog,
  loginUser,
  signUpUser
} from '../../actions/authActions';
import { saveLeadID } from '../../actions/applicationActions';

import Plan from '../Plan';
import Equipment from '../Equipment';
import Accessory from '../Accessory';
import ShoppingBagNoEquipmentDialog from './NoEquipmentDialog';
import AccessoriesInBagDialog from './AccessoriesInBagDialog';
import AuthDialog from './AuthDialog';
import MobileAuthDialog from './MobileAuthDialog';
import Loading from '../Shared/Loading/Loading';
import Footer from '../Shared/Footer';
import BackdropLoader from '../Shared/BackdropLoader'

import ShoppingBagUtil from '../../util/ShoppingBagUtil';
import Validator from '../../util/Validator';
import TwoFactorAuthenticationDialog
  from '../Shared/Dialog/TwoFactorAuthenticationDialog';
import {
  closeTwoFactorErrorDialog,
  getPhoneToken, getTwoFactorToken,
  validateSignInPhoneToken,
  setTokenVerificationCooldown,
  resetTokenVerificationAttempts,
  showMaxAttemptsErrorDialog
} from '../../actions/twoFactorActions';
import CommonUtil from '../../util/CommonUtil';
import formIds from '../../constants/formIds';
import routes from '../../constants/routes';
import TwoFactorVerificationErrorDialog
  from '../Shared/Dialog/TwoFactorVerificationErrorDialog';
import UhOhDialog from '../Shared/Dialog/UhOhDialog';
import messages from '../../constants/messages';

class ShoppingBag extends Component {

  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.updateItemQuantity = this.updateItemQuantity.bind(this);
    this.isMobile = Validator.isMobile(navigator.userAgent);
  }

  state = {
    displayEmptyBagDialog: false,
    hasAccessoriesOnBag: false,
    openAuthDialog: false
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const { notifications: prevNotifications } = prevProps.userExperience;
    const { notifications: currentNotifications } = this.props.userExperience;
    const { errors } = messages;

    /*Istanbul ignore else*/
    if (prevProps.application.key !== this.props.application.key) {
      this.props.userExperience?.userMKTData?.leadId && this.props.saveLeadId(this.props.userExperience.userMKTData.leadId);
    }
    if ((prevProps.userExperience.showSyncBagError !== this.props.userExperience.showSyncBagError) && this.props.userExperience.showSyncBagError) {
      this.setState({openAuthDialog: false});
    }
    if (prevNotifications.length !== currentNotifications.length ||
      prevNotifications[0]?.message !== currentNotifications[0]?.message) {
      if (currentNotifications[0]?.message === errors.invalidCSRF) {
        this.setState({ openAuthDialog: false });
      }
    }

    if (this.props.fraudVerification.tokenVerificationAttempts === 5) {
      if (this.props.fraudVerification.tokenVerificationCooldown && CommonUtil.checkTokenVerificationCooldown()) {
        this.props.resetTokenVerificationAttempts();
      }
    }
  }

  loadData() {
    if (!this.props.equipment.data.length || !this.props.shoppingBag.bag.length) {
      this.props.getEquipment();
    }
    if (!this.props.accessories.data.length || !this.props.shoppingBag.bag.length) {
      this.props.getAccessories();
    }
    if (!this.props.userExperience.terminals.data || !this.props.shoppingBag.bag.length) {
      this.props.getTerminals();
    }
    if (!this.props.userExperience.steps.shoppingBag) {
      this.props.changeStep('shoppingBag', this.props.userExperience.steps);
    }
  };

  updateItemQuantity(quantity, id) {
    this.props.updateItemQuantity(quantity, id);
  }

  validateBag = () => {
    const {shoppingBag} = this.props;
    const rentBag = shoppingBag.bag.length ? shoppingBag.bag.some(e => (e.equipmentType === 'Printer' || e.equipmentType === 'Other') && e.quantity > 0) : false;
    const hasItems = shoppingBag.bag.some(e => e.quantity >= 1) || rentBag;
    this.setState({
      displayEmptyBagDialog: !hasItems,
      hasAccessoriesOnBag: rentBag
    });
    if (hasItems) {
      this.setState({openAuthDialog: true});
      this.props.syncBag(shoppingBag);
    }
  };

  closeDialogAction = () => {
    this.setState({
      displayEmptyBagDialog: false,
      hasAccessoriesOnBag: false
    });
  };

  continueAction = () => {
    this.setState({
      displayEmptyBagDialog: false,
      hasAccessoriesOnBag: false,
      openAuthDialog: true
    });
    this.props.syncBag(this.props.shoppingBag);
  };

  closeAuthDialog = () => {
    this.setState({openAuthDialog: false});
  };

  closeTwoFactorAuthenticationDialog = () => {
    this.props.showSignInTwoFactorAuthenticationDialog(false);
    this.setState({openAuthDialog: false});
  }

  twoFactorTokenRequest = () => {
    this.props.getTwoFactorToken(this.props.user);
  };

  twoFactorVerificationFormSubmit = () => {
    this.props.submitTwoFactorForm();
  };

  phoneTokenRequest = () => {
    this.props.getPhoneToken(this.props.user);
  };

  phoneTokenVerification = (values) => {
    const userData = {
      selectedMerchantAccount: this.props.user.selectedMerchantAccount,
      email: document.querySelector('.signInForm input[name="email_address"]').value,
      password: document.querySelector('.signInForm input[name="password"]').value,
      code: values.validationCode
    }
    this.props.validatePhoneToken(userData).then((data) => {
      this.setState({openAuthDialog: false});
      this.props.resetTokenVerificationAttempts();
      this.closeTwoFactorAuthenticationDialog();
      this.props.changeLocation(routes.businessSignUp);
    }).catch((error) => {
      if (this.props.fraudVerification.tokenVerificationAttempts === 5) {
        this.props.setTokenVerificationCooldown();
        CommonUtil.setTokenVerificationAttempts(Date.now());
        this.closeTwoFactorAuthenticationDialog();
        this.setState({openAuthDialog: false});
        this.props.showMaxAttemptsErrorDialog();
      }
    })
  };

  phoneTokenVerificationFormSubmit = () => {
    this.props.phoneTokenVerificationFormSubmit();
  };

  closeTwoFactorErrorDialog = () => {
    this.props.closeTwoFactorErrorDialog();
  };

  render() {
    const {shoppingBag, equipment, accessories, application, auth, fraudVerification} = this.props;
    const {
      fetchingPhoneToken,
      validatingPhoneToken,
      tokenSent
    } = fraudVerification.phoneToken;

    const twoFactorVerificationFunctions = {
      requestCode: this.phoneTokenRequest,
      verifyCode: this.phoneTokenVerification,
      submitForm: this.phoneTokenVerificationFormSubmit
    };

    if (equipment.isFetching || shoppingBag.isFetching || accessories.isFetching) {
      return <Loading/>;
    } else {
      return (
        <>
          <BackdropLoader show={auth.isFetching || fetchingPhoneToken} />
          <div className='shoppingBag'>
            <div className='introText'>
              <h1>{'Create your order.'}</h1>
              <p>{'Select the Payanywhere hardware and/or accessories you need. The Smart Terminal Mini and POS+ are only available with custom pricing.'}</p>
            </div>
            <div className='productsWrapper'>
              <div className='planWrapper'>
                <p className='type'>{'Pricing'}</p>
                <Plan/>
              </div>
              <div className='hardwareWrapper'>
                <p className='type'>{'Hardware'}</p>
                {equipment.data.length !== 0
                  ? equipment.data.map((item, index) => (
                    <Equipment
                      updateQuantity={this.updateItemQuantity}
                      item={item}
                      key={index}
                      counter={shoppingBag.bag.find(bag => bag.equipmentId === item.equipmentId)}
                    />
                ))
                  : <p className='textCenter'>{'No items to display'}</p>}
              </div>
              <div className='accesoriesWrapper'>
                <p className='type'>{'Optional accessories'}</p>
                { equipment.data.length !== 0
                  ? accessories.data.map((item, index) => {
                    const counter = shoppingBag.bag.find(bag => bag.equipmentId === item.accessoryEquipmentId);
                    return (
                      <Accessory
                        key={index}
                        item={item}
                        updateQuantity={this.updateItemQuantity}
                        counter={counter}
                      />);
                })
                  : <p className='textCenter'>{'No items to display'}</p>}
              </div>
            </div>
          </div>
          <Footer
            buttonAction={this.props.application.threatMetrixError ? this.props.showUnexpectedErrorDialog : this.validateBag}
            counter={this.props.cartCounter}
            buttonLabel={application.executing ? 'Processing' : 'Continue'}
            buttonDisabled={application.executing}
          />
          <ShoppingBagNoEquipmentDialog
            isVisible={this.state.displayEmptyBagDialog}
            handleClose={this.closeDialogAction}
            continueAction={this.continueAction}
          />
          <AccessoriesInBagDialog
            isVisible={this.state.hasAccessoriesOnBag}
            handleClose={this.closeDialogAction}
            continueAction={this.continueAction}
          />
          {this.isMobile || window.innerWidth <= 780 ?
            <MobileAuthDialog
              {...this.props}
              isHidden={this.props.userExperience.showSignInTwoFactorAuthentication}
              isVisible={this.state.openAuthDialog}
              loginUser={this.props.loginUser}
              signUpUser={this.props.signUpUser}
              handleClose={this.closeAuthDialog}
            />
            : <AuthDialog
              {...this.props}
              isHidden={this.props.userExperience.showSignInTwoFactorAuthentication}
              isVisible={this.state.openAuthDialog}
              loginUser={this.props.loginUser}
              signUpUser={this.props.signUpUser}
              handleClose={this.closeAuthDialog}
              />
          }
          {
              <TwoFactorAuthenticationDialog
                isVisible={this.props.userExperience.showSignInTwoFactorAuthentication}
                user={this.props.user}
                closeAction={this.closeTwoFactorAuthenticationDialog}
                formActions={twoFactorVerificationFunctions}
                fetchingPhoneToken={fetchingPhoneToken}
                validatingPhoneToken={validatingPhoneToken}
                tokenSent={tokenSent}
                attempts={this.props.fraudVerification.tokenVerificationAttempts}
              />
          }
          {this.props.fraudVerification.phoneToken.error &&
            <TwoFactorVerificationErrorDialog
              handleClose={this.closeTwoFactorErrorDialog}
            />
          }
          {this.props.fraudVerification.showMaxAttemptsErrorDialog &&
            <UhOhDialog
              messageType={'maxAttemptsError'}
              closeAction={this.props.showMaxAttemptsErrorDialog}
              backRoute={'home'}
            />
          }
          {this.props.auth.loginError &&
          <UhOhDialog
            messageType={'authenticationFailed'}
            closeAction={this.props.closeLoginErrorDialog}
            backRoute={'home'}
          />
          }
          {
            (this.props.userExperience.showMeapiError || equipment.error || accessories.error) &&
            <UhOhDialog
              id='meapi-error-dialog'
              messageType={'somethingWentWrong'}
              closeAction={this.props.closeMeapiErrorDialog}
              backRoute={'home'}
            />
          }
        </>
      );
    }
  };
}

const mapStateToProps = (state) => ({
  user: state.user,
  application: state.application,
  accessories: state.accessories,
  equipment: state.equipment,
  shoppingBag: state.shoppingBag,
  cartCounter: ShoppingBagUtil.getShoppingBagQuantity(state.shoppingBag),
  auth: state.auth,
  userExperience: state.userExperience,
  fraudVerification: state.fraudVerification
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAccessories: () => dispatch(getAccessories()),
  getEquipment: () => dispatch(getEquipment()),
  getTerminals: () => dispatch(getNABItems()),
  updateItemQuantity: (quantity, id) => dispatch(updateItemQuantity(quantity, id)),
  syncBag: bag => dispatch(syncBag(bag)),
  loginUser: values => dispatch(loginUser(values)),
  signUpUser: values => dispatch(signUpUser(values)),
  changeLocation: route => ownProps.navigate(route),
  saveLeadId: value => dispatch(saveLeadID(value)),
  changeStep: (step, steps) => dispatch(changeStep(step, steps)),
  showUnexpectedErrorDialog: () => dispatch(showUnexpectedErrorDialog(true)),
  showSignInTwoFactorAuthenticationDialog: (display) => dispatch(showSignInTwoFactorAuthenticationDialog(display)),
  getPhoneToken: (user) => dispatch(getPhoneToken(user)),
  validatePhoneToken: (user) => dispatch(validateSignInPhoneToken(user)),
  submitTwoFactorForm: () => CommonUtil.submitFormById(formIds.twoFactorVerificationForm),
  phoneTokenVerificationFormSubmit: () => CommonUtil.submitFormById(formIds.twoFactorVerificationForm),
  getTwoFactorToken: user => dispatch(getTwoFactorToken(user)),
  closeTwoFactorErrorDialog: () => dispatch(closeTwoFactorErrorDialog()),
  closeLoginErrorDialog: () => dispatch(closeLoginErrorDialog()),
  setTokenVerificationCooldown: () => dispatch(setTokenVerificationCooldown()),
  resetTokenVerificationAttempts: () => dispatch(resetTokenVerificationAttempts()),
  showMaxAttemptsErrorDialog: () => dispatch(showMaxAttemptsErrorDialog()),
  closeMeapiErrorDialog: () => dispatch(closeMeapiErrorDialog())
});

export default compose(
  WithNavigation,
  connect(mapStateToProps, mapDispatchToProps)
)(ShoppingBag);
