/**


 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import TextField from '../Shared/Forms/TextField';
import TimePickerField from '../Shared/Forms/TimePicker';
import Validator from '../../util/Validator';
import TextUtil from '../../util/FormatTextUtil';
import formIds from '../../constants/formIds'
import _ from 'lodash';
import AddressForm from './AddressForm';
import messages from '../../constants/messages';
import SelectField from '../Shared/Forms/SelectField';
import { InputAdornment } from '@mui/material';
import createDecorator from 'final-form-calculate';

const accountTypesOptions = ['Checking', 'Savings'];

/* istanbul ignore next */
const validate = values => {
  if (_.isEmpty(values)) {
    return {};
  } else {
    return Validator.validateBusinessSignUpForm(values);
  }
};

const businessPercentCalculator = createDecorator(
  {
    field: 'business_type',
    updates: {
      card_present_percent_business: (fieldValue, formValues) => {
        if (fieldValue === 'Internet' || fieldValue === 'Moto') {
          return 0;
        }
        return 0;
      },
      keyed_percent_business: (fieldValue, formValues) => {
        if (fieldValue === 'Internet' || fieldValue === 'Moto') {
          return 0;
        }
        return 0;
      },
      moto_percent_business: (fieldValue, formValues) => {
        if (fieldValue === 'Internet') {
          return 0;
        }
        if (fieldValue === 'Moto') {
          return 100;
        }
        return 0;
      },
      internet_percent_business: (fieldValue, formValues) => {
        if (fieldValue === 'Internet') {
          return 100;
        }
        if (fieldValue === 'Moto') {
          return 0;
        }
        return 0;
      }
    }
  },
  {
    field: 'closing_settings',
    updates: {
      closing_time: (fieldValue, formValues) => {
        if (fieldValue === 'default') {
          return new Date(Date.now()).setHours(22, 0, 0, 0);
        }
        if (fieldValue === 'manual') {
          return null;
        }
        return formValues.closing_time;
      }
    }
  }
);

class BusinessSignUpForm extends Component {

  constructor (props) {
    super(props);
    this.closingSetting = [
      { label: 'Default auto close', value: 'default' },
      { label: 'Custom auto close', value: 'custom' },
      { label: 'Manual close', value: 'manual' },
    ];
  }

  componentWillMount () {
    this.loadData();
  }

  loadData () {

    this.accountTypeOptions = accountTypesOptions.map(option => ({
      label: option,
      value: option.toLowerCase()
    }));
    this.titleOptions = this.props.titleOptions.map(option => ({
      label: option,
      value: option
    }));
    this.statesOptions = this.props.statesOptions.map(option => ({
      label: option.description,
      value: option.code
    }));
    this.legalBusinessStructureOptions = this.props.legalBusinessStructureOptions.map(option => ({
      label: option.description,
      value: option.code
    }));
    this.businessTypeOptions = this.props.businessTypeOptions.map(option => ({
      label: option.marketType,
      value: option.marketType
    }));
    this.businessOptions = this.props.businessOptions.map(option => ({
      label: option.industryName,
      value: option.industryName
    }));
    this.industries = [{ value: '', label: 'Please select an industry' }];
    this.ticketAverageOptions = this.props.averageTicketAmountOptions.map(option => ({
      label: option.description,
      value: option.value
    }));
    this.monthlyVolumeOptions = this.props.averageMonthlyVolumeOptions.map(option => ({
      label: option.description,
      value: option.value
    }));

  }

  setDefaultTime = (event, value) => value === 'default' && this.props.resetClosingTime();

  render () {
    const {
      onSubmit,
      signInPath,
      routingNumberValid,
      isDisabled,
      initialValues
    } = this.props;

    const renderBusinessAddress = (
      <AddressForm
        {...this.props}
        statesOptions={this.statesOptions}
        address='business_street_address'
        secondAddress='business_second_address'
        city='business_city'
        state='business_state'
        zipCode='business_zip_code'
        streetLabel='Business address'
        secondStreetLabel='Suite or apt.'
      />
    );

    return (
      <Form
        onSubmit={onSubmit}
        decorators={[businessPercentCalculator]}
        initialValues={initialValues}
        validate={validate}
      >
        {
          ({ handleSubmit, values }) => {
            const isSoleProprietor = values.legal_business_structure === 'S';
            const selectedIndustry = values.industry;
            const selectedBusinessType = values.business_type;
            const selectedClosingSetting = values.closing_settings;
            const selectedMotoPercent = values.moto_percent_business;
            const selectedInternetPercent = values.internet_percent_business;
            const selectedCardPresentPercent = values.card_present_percent_business;
            const selectedKeyedPercent = values.keyed_percent_business;
            let currentTotalBusinessPercent = 0;

            const showWebsiteField = selectedBusinessType === 'Internet' ||
              (selectedBusinessType === 'Restaurant' && selectedInternetPercent > 0) ||
              (selectedBusinessType === 'Retail' && selectedInternetPercent > 0);

            [selectedMotoPercent, selectedInternetPercent, selectedCardPresentPercent, selectedKeyedPercent].forEach((value) => {
              if (value !== '') {
                currentTotalBusinessPercent += value;
              }
            });

            const showBusinessPercentError = selectedBusinessType && selectedBusinessType.length !== 0 && currentTotalBusinessPercent !== 100;

            this.industries = selectedIndustry
              ? this.props.businessOptions.find(industry => industry.industryName === selectedIndustry).details.map((option) => ({
                label: option.description,
                value: `${option.description}--${option.mccCode}`
              }))
              : [{ value: '', label: 'Please select an industry' }];

            return (
              <form onSubmit={handleSubmit}
                    id={formIds.businessSignUpForm}
                    className='businessFormContainer'
              >
                <div className='fieldsWrapper'>
                  <div className='formRow'>
                    <div className='formInput'>
                      <Field
                        label='DBA name'
                        type='text'
                        component={TextField}
                        name='DBA_name'
                        fullWidth
                        disabled={isDisabled}
                        parse={TextUtil.formatNoSpacesBefore}
                      />
                    </div>
                    <div className='formInput'>
                      <Field
                        label='Legal business name'
                        component={TextField}
                        name='corporate_business_name'
                        inputProps={{ maxLength: '30' }}
                        fullWidth
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                  <div className='formRow'>
                    <div className='formInput'>
                      <Field
                        label='Business phone'
                        placeholder='(000) 000-0000'
                        type='tel'
                        component={TextField}
                        name='business_telephone'
                        parse={TextUtil.formatPhoneNumber}
                        fullWidth
                        disabled={isDisabled}
                      />
                    </div>
                    <div className='formInput'>
                      <Field
                        label='Business email'
                        type='email'
                        component={TextField}
                        name='business_email'
                        fullWidth
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                  <div className='formRow'>
                    <div className='formInput'>
                      <Field
                        name='legal_business_structure'
                        id='legal_business_structure'
                        component={SelectField}
                        fullWidth
                        label='Legal business structure'
                        options={this.legalBusinessStructureOptions}
                        disabled={isDisabled}
                      />
                    </div>
                    <div className='formInput federalTax'>
                      <div className='placement'>
                        <span className='popoverWrap'> {'?'}
                          <span className='popover above'>
                            {messages.popover.taxId}
                          </span>
                        </span>
                      </div>
                      <Field
                        label='Federal tax ID'
                        component={TextField}
                        name='federal_tax_id'
                        parse={isSoleProprietor ? TextUtil.formatFullSSN : TextUtil.formatFederalTaxID}
                        fullWidth
                        placeholder={isSoleProprietor ? '000-00-0000' : '00-0000000'}
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                  <div className='formRow'>
                    <div className='formInput'>
                      <Field
                        name='business_type'
                        id='business_type'
                        component={SelectField}
                        fullWidth
                        label='Business type'
                        options={this.businessTypeOptions}
                        disabled={isDisabled}
                      />
                    </div>
                    <div className='formInput'>
                      <Field
                        name='industry'
                        id='industry'
                        component={SelectField}
                        fullWidth
                        label='Industry'
                        options={this.businessOptions}
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                  {showWebsiteField &&
                  <div className='formRow'>
                    <div className='formInput'>
                      <Field
                        label='Website'
                        component={TextField}
                        helperText='Example: https://www.payanywhere.com'
                        name='website'
                        fullWidth
                        disabled={isDisabled}
                      />
                    </div>
                  </div>}
                  <div className='formRow'>
                    <div className='formInput stacked'>
                      <Field
                        name='business_description'
                        id='business_description'
                        component={SelectField}
                        fullWidth
                        label='Business description'
                        options={this.industries}
                        disabled={isDisabled}
                      />
                    </div>
                    <div className='formRow stacked'>
                      <div className='formInput'>
                        <Field
                          name='ticket_amount'
                          id='ticket_amount'
                          component={SelectField}
                          fullWidth
                          label='Average ticket'
                          options={this.ticketAverageOptions}
                          disabled={isDisabled}
                        />
                      </div>
                      <div className='formInput'>
                        <Field
                          name='monthly_volume'
                          id='monthly_volume'
                          component={SelectField}
                          fullWidth
                          label='Monthly amount'
                          options={this.monthlyVolumeOptions}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='formRow'>
                    <div className='formInput'>
                      <Field
                        className='addBorder'
                        label='Card Present'
                        component={TextField}
                        name='card_present_percent_business'
                        parse={TextUtil.formatBusinessPercentage}
                        fullWidth
                        disabled={isDisabled || !selectedBusinessType || (selectedBusinessType === 'Internet') || (selectedBusinessType === 'Moto')}
                        placeholder='0'
                        InputProps={{
                          endAdornment:
                            <InputAdornment position='end'>
                              {'%'}
                            </InputAdornment>
                        }}
                      />
                    </div>
                    <div className='formInput'>
                      <Field
                        className='addBorder'
                        label='Keyed'
                        component={TextField}
                        name='keyed_percent_business'
                        parse={TextUtil.formatBusinessPercentage}
                        fullWidth
                        disabled={isDisabled || !selectedBusinessType || (selectedBusinessType === 'Internet') || (selectedBusinessType === 'Moto')}
                        placeholder='0'
                        InputProps={{
                          endAdornment:
                            <InputAdornment position='end'>
                              {'%'}
                            </InputAdornment>
                        }}
                      />
                    </div>
                    <div className='formInput'>
                      <Field
                        data-test='moto-percent'
                        className='addBorder'
                        label='MOTO'
                        component={TextField}
                        name='moto_percent_business'
                        parse={TextUtil.formatBusinessPercentage}
                        fullWidth
                        disabled={isDisabled || !selectedBusinessType || (selectedBusinessType === 'Internet') || (selectedBusinessType === 'Moto')}
                        placeholder='0'
                        props={(selectedBusinessType === 'Moto') ? { value: 100 } : {}}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position='end'>
                              {'%'}
                            </InputAdornment>
                        }}
                      />
                    </div>
                    <div className='formInput'>
                      <Field
                        data-test='internet-percent'
                        className='addBorder'
                        label='Internet'
                        component={TextField}
                        name='internet_percent_business'
                        parse={TextUtil.formatBusinessPercentage}
                        fullWidth
                        disabled={isDisabled || !selectedBusinessType || (selectedBusinessType === 'Internet') || (selectedBusinessType === 'Moto')}
                        placeholder='0'
                        props={(selectedBusinessType === 'Internet') ? { value: 100 } : {}}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position='end'>
                              {'%'}
                            </InputAdornment>
                        }}
                      />
                    </div>
                  </div>
                  <div className='bottomSpacer businessPercentNote'>
                    {
                      showBusinessPercentError &&
                      <p className='error'>
                        {`*${messages.errors.invalidBusinessPercent}.`}
                        <b>{`Current ${currentTotalBusinessPercent}%`}</b>
                      </p>
                    }
                    <p>
                      {`*${messages.validator.approximateBusinessPercent}.`}
                    </p>
                  </div>
                  <div className='formRow'>
                    <div className='formInput stacked'>
                      <Field
                        name='account_type'
                        id='account_type'
                        component={SelectField}
                        fullWidth
                        label='Bank account type'
                        options={this.accountTypeOptions}
                        disabled={isDisabled}
                      />
                    </div>
                    <div className='formRow stacked'>
                      <div className='formInput'>
                        <Field
                          label='Routing number'
                          component={TextField}
                          name='bank_routing_number'
                          parse={TextUtil.formatABANumber}
                          validation={routingNumberValid}
                          fullWidth
                          disabled={isDisabled}
                        />
                      </div>
                      <div className='formInput federalTax'>
                        <div className='placement'>
                          <span className='popoverWrap'> {'?'}
                            <span className='popover above'>
                              {messages.popover.accountNumber}
                            </span>
                          </span>
                        </div>
                        <Field
                          label='Account number'
                          component={TextField}
                          name='account_number'
                          parse={TextUtil.formatAccountNumber}
                          fullWidth
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                  </div>
                  {signInPath &&
                  <>
                    <div className='formRow personal-data'>
                      <div className='formInput'>
                        <Field
                          className='addBorder'
                          label='First name'
                          component={TextField}
                          name='customer_first_name'
                          margin='normal'
                          variant='outlined'
                          fullWidth
                          disabled={isDisabled}
                          validate={Validator.requiredValue}
                        />
                      </div>
                      <div className='formInput'>
                        <Field
                          className='addBorder'
                          label='Last name'
                          component={TextField}
                          name='customer_last_name'
                          fullWidth
                          disabled={isDisabled}
                          validate={Validator.requiredValue}
                        />
                      </div>
                    </div>
                    <div className='formRow personal-data'>
                      <div className='formInput stacked'>
                        <Field
                          name='customer_title'
                          id='customer_title'
                          component={SelectField}
                          fullWidth
                          label='Title'
                          options={this.titleOptions}
                          disabled={isDisabled}
                          validate={Validator.requiredValue}
                        />
                      </div>
                      <div className='formRow stacked'>
                        <div className='formInput'>
                          <Field
                            fullWidth
                            label='SSN'
                            placeholder='000-00-0000'
                            component={TextField}
                            name='customer_ssn'
                            parse={TextUtil.formatFullSSN}
                            disabled={isDisabled}
                            validate={Validator.requiredValue}
                          />
                        </div>
                        <div className='formInput'>
                          <Field
                            label='Date of birth'
                            placeholder='MM/DD/YYYY'
                            component={TextField}
                            parse={TextUtil.formatDOB}
                            name='customer_date_of_birth'
                            fullWidth
                            disabled={isDisabled}
                            validate={Validator.requiredValue}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                  }
                  {renderBusinessAddress}
                  <div className='formRow'>
                    <div className='formInput'>
                      <Field
                        name='closing_settings'
                        id='closing_settings'
                        component={SelectField}
                        fullWidth
                        label='Closing settings'
                        options={this.closingSetting}
                        disabled={isDisabled}
                      />
                    </div>
                    {selectedClosingSetting !== 'manual' &&
                    <div className='formInput smallBox'>
                      <Field
                        fullWidth
                        label='Closing time'
                        component={TimePickerField}
                        name='closing_time'
                        disabled={isDisabled || selectedClosingSetting === 'default'}
                        parse={(value) => {
                          return value ? new Date(value.format()).getTime() : null;
                        }}
                      />
                    </div>}
                  </div>
                  <p className='inputLabel'>
                    {messages.closingSettings[selectedClosingSetting]}
                  </p>
                </div>
              </form>
            );
          }
        }
      </Form>
    );
  };
}

function mapStateToProps (state) {
  let initialValues = {};

  const userData = state.user && state.user.data;
  const mobileNumber = state.user && userData.cell || '';
  const emailAddress = state.user && userData.username;
  const profile = state.accountProfile?.data || {};
  const cookies = document.cookie.split('; ');

  const signInPath = !!(state.user?.data?.user_id);
  const routingNumberValid = state.businessSignUp?.error ? state.businessSignUp?.error : null;
  initialValues.DBA_name = profile.business_name || null;
  initialValues.corporate_business_name = profile.business_name;
  initialValues.legal_business_structure = null;
  initialValues.business_telephone = TextUtil.formatPhoneNumber(mobileNumber);
  initialValues.business_email = emailAddress;
  initialValues.federal_tax_id = null;
  initialValues.business_type = null;
  initialValues.industry = null;
  initialValues.business_description = null;
  initialValues.ticket_amount = null;
  initialValues.monthly_volume = null;
  initialValues.bank_routing_number = null;
  initialValues.account_number = null;
  initialValues.account_type = null;
  initialValues.business_street_address = profile.business_address;
  initialValues.business_city = profile.business_city;
  initialValues.business_state = profile.business_state;
  initialValues.business_zip_code = profile.business_zip;
  initialValues.has_fax_number_website = false;
  initialValues.website = null;
  initialValues.closing_settings = 'default';
  initialValues.closing_time = new Date().setHours(22, 0, 0, 0);
  initialValues.customer_first_name = profile?.principal && profile.principal.substr(0, profile.principal.indexOf(' '));
  initialValues.customer_last_name = profile?.principal && profile.principal.substr(profile.principal.indexOf(' ') + 1);
  initialValues.card_present_percent_business = 0;
  initialValues.keyed_percent_business = 0;
  initialValues.moto_percent_business = 0;
  initialValues.internet_percent_business = 0;

  /* istanbul ignore next */
  _.each(cookies, (cookie) => {
    if (cookie.includes('visitor_id321901=')) {
      initialValues.visitor_id = cookie.replace('visitor_id321901=', '');
    }
  });

  return {
    initialValues,
    profile,
    signInPath,
    routingNumberValid
  };
}

BusinessSignUpForm = connect(mapStateToProps, null, null, { forwardRef: true })(BusinessSignUpForm);
export default BusinessSignUpForm;
