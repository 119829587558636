/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

export default {

  whatsNew: {
    message: 'Introducing Online Payments! Accept payments right in your browser.',
    title: 'New in this Version'
  },
  tooLarge: {
    message: 'Upload too large: please try a smaller size (under 2MB)'
  },
  validator: {
    requiredText: 'This field is required',
    invalidText: 'This field is invalid',
    invalidDbaName: 'This field cannot be a space or blank',
    invalidPhoneNumber: 'This phone number is invalid',
    invalidMobileNumber: 'Invalid mobile number',
    invalidTaxId: 'Invalid tax ID',
    invalidEmail: 'This email address is invalid',
    invalidLastName: 'Last name must be two or more characters',
    passwordsDoNotMatch: 'New Password and Confirm Passwords do not match!',
    emailsDoNotMatch: 'New Email and Confirm Email Addresses do not match!',
    securityCodeRequired: 'Security code is required',
    securityCodeShouldBeThreeDigits: 'Security code should be 3 digits',
    securityCodeShouldBeFourDigits: 'Security code should be 4 digits',
    streetNumberRequired: 'Street number is required',
    zipRequired: 'Zip is required',
    zipRequiredWithZipPlusFour: 'Zip is required with Zip +4',
    zipShouldBeFiveDigits: 'Zip should be 5 digits',
    zipPlusFourShouldBeFourDigits: 'Zip +4 should be 4 digits',
    creditCardRequired: 'Credit card number is required',
    creditCardInvalid: 'Credit card number is invalid',
    expirationDateRequired: 'Expiration date is required',
    expirationDateInvalid: 'Expiration date is invalid',
    expirationDateExpired: 'Expiration date is expired',
    firstLastNameRequired: 'First and last name required',
    invalidName: 'Invalid name',
    invalidDate: 'Invalid date',
    abaInvalidNumber: 'Invalid ABA routing number',
    invalidValue: 'Invalid Value',
    invalidPassword: 'Passwords must have at least 8 characters and contain one uppercase, one lowercase letter, and one number or symbol (~!@#$%^&*()-=+).',
    invalidDOB: 'You must be at least 18 years old.',
    invalidAccountNumber: 'The account number cannot be less than 4 digits, and not greater then 17 digits, and not equal to 16 digits',
    invalidWebsite: 'Website is not in the correct format. Example: https://www.payanywhere.com',
    invalidFaxNumber: 'Invalid fax number.',
    invalidCorporateName: 'Cannot be longer than 30 characters.',
    customerEquityOwnershipMore: 'Equity ownership value cannot be more than 100%',
    customerEquityOwnershipLess: 'Equity ownership value cannot be less than 100%',
    invalidPercent: 'Invalid percent',
    approximateBusinessPercent: 'Enter the approximate percent of sales for each payment method above.',
    invalidSsnNumber: 'This ssn is invalid',
    invalidFile: 'Invalid file'
  },
  errors: {
    completePreAuthError: 'Complete Pre Auth Declined',
    avs: 'The street number and/or zip code entered does not match the billing address of the cardholder. Would you like to proceed anyway?',
    requiredFields: 'Required fields are missing',
    taskFailure: 'An error has occurred',
    existingUser: 'User already exist please sign in',
    invalidZipCode: 'Invalid zip code',
    giactImageFailure: 'We were unable to verify the banking information you have provided. Please try again or upload an image of a voided check with your banking information clearly visible.',
    giactFailure: 'We were unable to verify the banking information you have provided. Please make sure the information is correct. If the information is correct and you are still receiving an error, we may contact you for a voided check in order to validate your deposit account.',
    twoFactorTokenError:'You\'ve entered an incorrect verification code. Request a new code to try again.',
    internalProcessingError: 'Sorry, internal processing error.',
    invalidCSRF: 'We have updated our application. Please refresh your browser.',
    invalidBusinessPercent: 'Total percentages must equal 100%',
    invalidRoutingNumber: 'Invalid ABA Routing Number.'
  },
  legal: {
    headerA: 'Updated Terms.',
    paCopy1: 'We are updating our Payanywhere payment processing services. This update does not change your pricing. In order to continue using Payanywhere, you will be required to review and agree to the updated terms.',
    headerRequired: 'Action Required',
    copyRequired: 'To continue using Payanywhere Apply, the Business Principal/owner of this account must login and agree to the updated terms. Please contact your administrator.',
    userAgree: 'I have reviewed and agree to the User Agreement.',
    pricingAgree: 'I agree to the Pricing',
    equipmentAgree: 'I agree to the Equipment Agreement',
    linkText: ' Agree '
  },
  popover: {
    taxId: 'If you are a sole proprietor and use your SSN as your Fed Tax ID, please enter your SSN here.',
    accountNumber: 'On the bottom of a check, find the Routing Number on the left and your Account Number on the right.',
    ccv: 'The 3 or 4 digit number located on your card.'
  },
  shoppingBagNoEquipment: {
    accessoriesOnly: 'The Payanywhere printer and cash drawer are compatible with Android. For a list of iOS-compatible accessories, visit the ',
    noEquipment: 'You have not selected any hardware. You can purchase a credit card reader later in Payments Hub. Click Continue to confirm that you want to proceed without hardware.'
  },
  uhohMessages: {
    processInformationFailed: 'An error as ocurred and we are unable to process your information. Please try again or contact Customer Service at ',
    authenticationFailed: 'We are unable to verify your identity. Please try again or contact Customer Service at ',
    applicationFailed: 'Your application could not be sent. Please try again or contact Customer Service at ',
    creditcardPaymentFailure: 'We are unable to process your payment. Please try again or contact Customer Service at ',
    failedToProcessPayment: 'Your application has been sent, but we are unable to process your payment. Please contact Customer Service at ',
    threeTimesPaymentFailure: 'We were unable to process your payment. To complete the transaction, please sign into your account or contact Customer Service at ',
    somethingWentWrong: 'Something went wrong. Please try again or contact Customer Service at ',
    invalidRoutingNumber: 'Invalid routing number. Please use a valid routing number and try again or contact Customer Service at ',
    maxAttemptsError: 'Max send attempts reached. Please try again later or contact Customer Service at '
  },
  loading: ['Please wait...', 'One moment please...'],
  twoFactorMessages: {
    twoFactorSSNEnabled: 'We were unable to verify your identity based on the information you provided. For verification purposes, please enter full SSN and the code you received on your mobile device.',
    twoFactorSSNDisabled: 'We were unable to verify your identity based on the information you provided. For verification purposes, please enter full SSN.',
    twoFactorIdologyEnabled: 'The privacy and security of our customers is our number one priority. Please enter the code you received on your mobile device and answer the three simple questions below to confirm your identity.',
    twoFactorIdologyDisabled: 'The privacy and security of our customers is our number one priority. Please answer the three simple questions below to confirm your identity.'
  },
  tokenRequest: 'Request New Token',
  closingSettings: {
    default: 'The default auto close time is 10:00 pm ET / 7:00 pm PT with next day funding for all transactions settled prior to that time. Select to auto close at a custom time or close manually if the default does not suit your business. Selecting a time after 10:00 pm ET / 7:00 pm PT will delay next day funding.',
    custom: 'Select a set time to automatically close all transactions and send them off for settlement. All transactions must be adjusted for tip prior to the time you have selected. Your funding may be impacted by the time you set.',
    manual: 'Manually close transactions and send them off for settlement. Your funding may be impacted depending on when you close your transactions.'
  },
  twoFactorAuth:{
    header:' Verify it\'\s you',
    verification:{
      headerText:'Two-factor authentication is a security process used to help keep your account safe. When you make edits to your account information, you will be prompted for a unique code sent by SMS. Message and data rates may apply.',
      missingNumber:'Looks like we do not have a mobile number on file for you. Please add your mobile number before making any changes to your account.',
      error: 'We were unable to verify your identity. Please call Customer Care at 877.464.4218 in order to update your mobile number.'
    }
  },
};
