/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import actionTypes from '../constants/actionTypes';
import {CALL_API} from '../middleware/api';
import ShoppingBagUtil from '../util/ShoppingBagUtil';
import dayjs from '../config/DayjsConfig';

export function addItemToBag(item) {

  let id = item.accessoryEquipmentId || item.equipmentId;

  return {
    type: actionTypes.addItem,
    item: {
      equipmentId: id,
      quantity: 1,
      equipmentType:item.equipmentType || item.accessoryDetail[0].equipmentType
    },
    isAccessory: (item.accessoryEquipmentId > 0)
  };

}

export function updateItemQuantity(quantity, id) {
  return {
    type: actionTypes.updateQuantity,
    item: {
      equipmentId: id,
      quantity: quantity,
    },
  };
}

export function addPlanToBag(plan) {

  return {
    type: actionTypes.selectPlan,
    plan
  }

}

export function removeFromBag(item) {

  let id = item.accessoryEquipmentId || item.equipmentId;

  return {
    type: actionTypes.removeItem,
    equipmentId: id,
    isAccessory: (item.accessoryEquipmentId > 0)
  }

}

export function removeAllItemsFromBag(clear) {

  localStorage.removeItem('pa-apply-application-id');
  localStorage.removeItem('pa-apply-session-id');
  localStorage.removeItem('pa-apply-token');
  localStorage.removeItem('pa-apply-u');
  localStorage.removeItem('pa-apply-ma');
  localStorage.removeItem('pa-apply-signup');
  localStorage.removeItem('pa-shopping-bag');
  localStorage.removeItem('pa-giactValidation');
  localStorage.removeItem('pa-apply-phone-authenticated');

  return {
    type: clear ? actionTypes.clearAllItems : actionTypes.removeAllItems
  }

}

export function syncBag(bag) {

  let key = localStorage.getItem('pa-apply-application-id') || null;

  const endpoint = `application/${key}/syncBag`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      encrypted: false,
      types: [actionTypes.syncBagRequest, actionTypes.syncBagSuccess, actionTypes.syncBagFailure],
      body: bag
    }
  };
}

export function processNABPayment(user, terminals, values, shoppingBagItems, mid, statusId) {

  /////////////////////////////////////////////////////////////////////
  // Process a NAB account payment by credit card
  // Note: No Tax, No handling rental items
  /////////////////////////////////////////////////////////////////////

  const endpoint = 'equipment/sales-invoice/nab';
  const expirationDate = dayjs(values.edate, 'MM-YY').format('YYYY-MM-DDT00:00:00UTC');
  let totalOrder = 0;

  const promoItem = {};
  const lineItems = [];
  let lineItem = {};
  let priceId;
  let quantity;

  const zipCode = values.zipPlus4 ? values.zip + values.zipPlus4 : values.zip;

  terminals.data.map((terminal) => {
    shoppingBagItems.forEach((item) => {
      if(item.equipmentId === Number(terminal.details.prices[0].barcode)) {
        quantity = item.quantity;
        priceId =  terminal.details.prices[0].id;

        if(item.equipmentId === 791) {
          promoItem.pi = priceId; //price_id
          promoItem.q = 1; // quantity
          promoItem.tr = 0.00; //tax_rate
          promoItem.dr = 100; //discount_rate
          promoItem.da = 0.00; //discount_amount
          promoItem.osmi = []; //option_set_member_ids
          promoItem.eo = []; //express_options

          lineItems.push(promoItem);
          quantity = quantity - 1;
        }

        if (quantity > 0) {
          lineItem = {};
          lineItem.pi = priceId; //price_id
          lineItem.q = quantity; // quantity
          lineItem.tr = 0.00; //tax_rate
          lineItem.dr = 0; //discount_rate
          lineItem.da = 0.00; //discount_amount
          lineItem.osmi = []; //option_set_member_ids
          lineItem.eo = []; //express_options

          const productTotal = Number(terminal.details.prices[0].price) * quantity;
          totalOrder = Math.round((productTotal + totalOrder) * 100) / 100;

          lineItems.push(lineItem);
        }
      }

    });

  });


  let body = {
    o: { //order
      ai: localStorage.getItem('pa-apply-application-id'), //application_id
      m: mid, //mid
      li: lineItems, //line_items
      to: parseFloat(Math.round(totalOrder * 100) / 100).toFixed(2), //total amount
      ta: 0.00 // tax_amount
    },
    ts: `${identity}|${applicationVersion}`, //transaction source
    cc: { //credit_card
      ed: expirationDate, //expiration_date
      it: 'KEYED', //input_type
      n: values.cdigits, //number
      sc: values.cvv, //security_code
      zc: zipCode, //zip_code
      sn: values.street_number, //street_numeral
    },
    ta: user.data.username, //to_address
    b: 'payanywhere', //brand
    s: statusId //status
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.creditCardPaymentRequest, actionTypes.creditCardPaymentSuccess, actionTypes.creditCardPaymentFailure],
      body
    }
  };

}

export function postEquipmentOrder(user, shoppingBagItems, shipping, statusId, groupId) {

  // shipping always ground for now
  let shippingMethod = 'GROUND';
  let selectedShippingChoice = shipping && shipping.data ? shipping.data.find(item => item.Service.Code === shipping.selected ) : null;
  let query = statusId ? `?statusID=${statusId}` : '';

  if (selectedShippingChoice) {
    let shippingLabel = ShoppingBagUtil.getShippingLabel(selectedShippingChoice);
    shippingMethod = shippingLabel.internalCode;
  }

  let body = {
    type: 'PA',
    shipping_method: shippingMethod,
    order: [],
    group_id: groupId
  };

  // does not include rental items

  shoppingBagItems.forEach((item) => {
    const supplyOrderDetails = {};
    supplyOrderDetails.supply_id = item.equipmentId;
    supplyOrderDetails.quantity = item.quantity;
    body.order.push(supplyOrderDetails);
  });


  return {
    [CALL_API]: {
      endpoint: `equipment/users/accounts/equipment/order${query}`,
      method: 'POST',
      authenticated: true,
      types: [actionTypes.equipmentOrderRequest, actionTypes.equipmentOrderSuccess, actionTypes.equipmentOrderFailure],
      body
    }
  };
}
