export default {
  attachFiles: {
    accept: [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/tiff',
      'image/heic',
      'application/pdf'
    ],
    maxSize: 5000000
  }
}
